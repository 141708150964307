import cx from 'classnames';
import every from 'lodash/every';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import React, { PureComponent } from 'react';

import FieldContainer from '../components/FieldContainer';
import StringSummary from '../components/StringSummary';
import { isFieldLocked } from '../components/utils';
import { evalCondition, mapCondition } from '../utils/conditions';
import { setIn } from '../utils/objectUtils';

import CdnImage from '../components/CdnImage';
import InputBlocker from '../components/InputBlocker';
import { filterOptions } from '../utils/fieldUtils';
import './radio-field.scss';
import Quota from '../components/Quota';

function orderTagValues(values, options) {
  return orderBy(values, (v) => findIndex(options, (opt) => opt.value === v));
}

class MultiRadioField extends PureComponent {
  getValues = () => {
    const { value } = this.props;
    if (!value) return [];
    if (Array.isArray(value)) return value;
    if (typeof value === 'string') {
      return JSON.parse(value);
    }
    console.warn('Unknown value type', typeof value, value);
    return [];
  };

  handleChange = (value) => {
    const { name, onChange, maxCount, data } = this.props;

    let values = this.getValues();
    if (values.indexOf(value) !== -1) {
      // Remove
      values = values.filter((v) => v !== value);
    } else {
      // Add
      values = [...values, value];
    }
    if (maxCount && values.length > maxCount) {
      return; // Block editing
    }

    // Order values in the same order as options
    values = orderTagValues(values, this.props.options);

    onChange(name, values.length === 0 ? [] : values);
  };

  render() {
    const { name, options, vertical, required = false, disabled, locked, strings, mode, summary, label, value, quotaMode } = this.props;
    const values = this.getValues();
    const isLocked = isFieldLocked(name, locked);
    const initialUserValue = get(window.__DATA__.data, name);

    if (mode === 'summary') {
      const optionsByValue = keyBy(options, 'value');
      const labelValue = (value || []).map((v) => optionsByValue[v]?.label ?? v).join(', ');
      return <StringSummary label={label} value={labelValue} summary={summary} />;
    }
    return (
      <FieldContainer {...this.props} vertical={vertical} as="div">
        <div className="af-radios">
          {/* Used to block submit */}
          <input
            name={name}
            required={required}
            value={values.length ? values.join(',') : ''}
            style={{ position: 'absolute', opacity: 0, width: '100%', height: 0 }}
          />
          {options.map((option, idx) => {
            const { value: radioValue, label, description, quota, usersCount, labelIfFull } = option;

            const checked = values.indexOf(radioValue) !== -1;
            const remainingPlaces = typeof quota === 'number' ? Math.max(0, quota - (usersCount || 0)) : undefined;
            const isFull = remainingPlaces === 0 && initialUserValue !== radioValue;
            const optionDisabled = disabled || isFull;
            return (
              <label key={idx} className={cx('af-radio-label', checked && 'is-active', optionDisabled && 'is-disabled')}>
                <input
                  className="af-checkbox-field"
                  type="checkbox"
                  value={radioValue}
                  checked={checked}
                  disabled={optionDisabled}
                  readOnly={optionDisabled || isLocked}
                  onChange={optionDisabled || isLocked ? undefined : () => this.handleChange(radioValue)}
                />
                <span>
                  {(isFull && labelIfFull) || label}
                  {description && <div className="description">{description}</div>}
                  {typeof quota === 'number' && <Quota roomLeft={remainingPlaces} quota={quota} strings={strings} quotaMode={quotaMode} />}
                </span>
              </label>
            );
          })}
        </div>
      </FieldContainer>
    );
  }
}

export default class RadioField extends PureComponent {
  evalCondition = (condition, data) => {
    // Check for dynamique values
    return evalCondition(mapCondition(condition, data), data);
  };

  handleChange(name, value) {
    const { onChange, confirmationPrompt, data, inputMode } = this.props;
    if (confirmationPrompt?.message) {
      const { message, condition } = confirmationPrompt;

      const finalValue = inputMode === 'number' ? +value : value;
      const newData = setIn(data, name, finalValue);

      // Simulate new data
      if (!condition || this.evalCondition(condition, newData)) {
        if (!window.confirm(message)) {
          return;
        }
      }
    }
    onChange(name, value);
  }

  render() {
    const {
      data,
      mode,
      summary,
      label,
      name,
      options,
      value,
      vertical,
      required = false,
      disabled,
      locked,
      allowMultiple,
      strings,
      quotaMode,
    } = this.props;
    const visibleOptions = filterOptions(data, options);
    if (allowMultiple) return <MultiRadioField {...this.props} options={visibleOptions} />;
    const isLocked = isFieldLocked(name, locked);

    const initialUserValue = get(window.__DATA__.data, name);

    if (mode === 'summary') {
      const labelValue = options.find((o) => o.value === value)?.label;
      return <StringSummary label={label} value={labelValue} summary={summary} />;
    }

    const optionsToShow = visibleOptions.map((option) => {
      const { value: radioValue, quota, usersCount } = option;
      const remainingPlaces = typeof quota === 'number' ? Math.max(0, quota - (usersCount || 0)) : undefined;
      const isFull = remainingPlaces <= 0 && initialUserValue !== radioValue;
      const optionDisabled = disabled || isFull;
      return { ...option, remainingPlaces, isFull, disabled: optionDisabled };
    });

    // If everything is full, the browser will disable the "required" prop, we'll need to lock it
    const isFieldFull = every(optionsToShow, 'isFull');
    const selectedOption = optionsToShow.find((opt) => opt.value === value);

    return (
      <FieldContainer {...this.props} vertical={vertical} as="div">
        {!disabled && required && isFieldFull && (!value || selectedOption?.isFull) && <InputBlocker name={name} />}
        <div className="af-radios">
          {optionsToShow.map((option, idx) => {
            const { value: radioValue, label, image, description, quota, remainingPlaces, labelIfFull, isFull, disabled: optionDisabled } = option;

            // == to allow matching string vs number
            const checked = value == radioValue;

            return (
              <label key={idx} className={cx('af-radio-label', checked && 'is-active', optionDisabled && 'is-disabled')}>
                <input
                  className="af-radio-field"
                  type="radio"
                  name={name}
                  value={radioValue}
                  required={required}
                  checked={checked}
                  disabled={optionDisabled}
                  readOnly={optionDisabled || isLocked}
                  onChange={optionDisabled || isLocked ? undefined : () => this.handleChange(name, radioValue)}
                />
                {image && (
                  <div className="af-radio-image">
                    <CdnImage maxWidth={120} maxHeight={120} src={image} srcSet />
                  </div>
                )}
                <span>
                  {(isFull && labelIfFull) || label}
                  {description && <div className="description">{description}</div>}
                  {typeof quota === 'number' && <Quota roomLeft={remainingPlaces} quota={quota} strings={strings} quotaMode={quotaMode} />}
                </span>
              </label>
            );
          })}
        </div>
      </FieldContainer>
    );
  }
}
