/* eslint-disable react/prop-types */
import cx from 'classnames';
import i18next from 'i18next';
import React, { PureComponent } from 'react';
import FieldContainer from '../components/FieldContainer';
import Select from '../components/FixedRequiredSelect';
import InputBlocker from '../components/InputBlocker';
import Message from '../components/Message';
import { replaceValues } from '../components/utils';
import { filterOptions } from '../utils/fieldUtils';
import { Error } from './errors';
import './MultiSelectField.scss';

export function valueOf(v) {
  if (!v) return '';
  if (typeof v === 'object') return v.value;
  return v;
}

const translationPrefix = 'multi-select';

export default class MultiSelectField extends PureComponent {
  handleChange = (value) => {
    const { name, maxItems, onChange } = this.props;
    const values = (value || []).map(valueOf);
    const length = values.length;
    if (maxItems && length > maxItems) {
      onChange(name, values.slice(0, maxItems));
      // TODO: show error message if we have more ?
    } else {
      onChange(name, values);
    }
  };

  /**
   * Convert string[] to object[]
   */
  expandValues = (values) => {
    if (!values) return [];
    const { options = [] } = this.props;
    return values
      .map(valueOf)
      .map((v) => options.find((o) => o.value === v))
      .filter((v) => v);
  };

  render() {
    const {
      label,
      data,
      mode,
      name,
      summary,
      value,
      options = [],
      placeholder,
      required,
      disabled,
      searchable = true,
      maxItems,
      minItems,
      position = 'fixed',
      strings,
    } = this.props;
    const values = this.expandValues(value);
    const visibleOptions = filterOptions(data, options);

    if (mode === 'summary') {
      if (summary?.hidden) {
        return null;
      }

      return (
        <FieldContainer {...this.props} required={false} label={summary?.label || label}>
          {values.map((v, index) => (
            <div key={index} className="field-value">
              {v.label}
            </div>
          ))}
        </FieldContainer>
      );
    }

    return (
      <FieldContainer {...this.props}>
        <div className="af-multi-select-container">
          <Select
            className={cx('multiselect', 'field', name)}
            value={values}
            required={required}
            placeholder={placeholder || label}
            onChange={this.handleChange}
            options={visibleOptions}
            isMulti={true}
            isSearchable={searchable}
            isDisabled={disabled}
            isOptionDisabled={() => maxItems && values?.length >= maxItems}
            max={maxItems}
            min={minItems}
            menuPosition={position}
          />
          {/* Inject values in HTML POST as {name}[] to create a JSON array */}
          {value && value.map((v) => <input key={valueOf(v)} name={name + '[]'} value={valueOf(v)} readOnly style={{ display: 'none' }} />)}
          {!!minItems && values.length < minItems && (
            <Error
              message={replaceValues(strings?.minNotReached, { minItems }) || i18next.t(`${translationPrefix}.min-not-reached`, { count: minItems })}
            >
              <InputBlocker name={name} />
            </Error>
          )}
          {!!maxItems && values.length == maxItems && (
            <Message className="af-message--info">
              {replaceValues(strings?.maxReached, { maxItems }) || i18next.t(`${translationPrefix}.max-reached`, { count: maxItems })}
            </Message>
          )}
          {!!maxItems && values.length > maxItems && (
            <Error message={replaceValues(strings?.maxReached, { maxItems }) || i18next.t(`${translationPrefix}.max-reached`, { count: maxItems })}>
              <InputBlocker name={name} />
            </Error>
          )}
        </div>
      </FieldContainer>
    );
  }
}
