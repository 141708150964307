/* eslint-disable react/prop-types */
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import FieldContainer from '../components/FieldContainer';
import { formatDate } from '../utils/dateUtils';

import { StringField } from './index';
import { locales } from '../utils/date';

const isAfterToday = (date) => {
  const currentDate = moment().format('YYYY-MM-DD');
  return moment(date).isSameOrAfter(currentDate);
};

function parseDateString(value, { showTimeSelect, showTimeSelectOnly, storeIsoDate }) {
  if (!value) return null;

  if (storeIsoDate) {
    return moment(value, moment.ISO_8601).toDate();
  }
  if (showTimeSelectOnly) {
    const today = moment().format('YYYY-MM-DD');
    return moment(`${today} ${value}`, 'YYYY-MM-DD HH:mm').toDate(); // hack: inject today with the selected time (HH:mm) because DatePicker neead a date
  }
  if (showTimeSelect) {
    return moment(value, 'YYYY-MM-DD HH:mm').toDate();
  }
  return moment(value, 'YYYY-MM-DD').toDate();
}

const computeIntervals = (includeDateIntervales, showTimeSelect) => {
  const hasIncludeIntervals = includeDateIntervales?.length > 0;
  if (hasIncludeIntervals) {
    const includeIntervals = includeDateIntervales
      .filter((range) => !!range.startDate && !!range.endDate)
      .map((range) => {
        if (!showTimeSelect && !range.startDate.includes('T')) {
          return {
            start: moment(range.startDate, 'YYYY-MM-DD').startOf('day').toDate(),
            end: moment(range.endDate, 'YYYY-MM-DD').endOf('day').toDate(),
          };
        }
        return { start: new Date(range.startDate), end: new Date(range.endDate) };
      });
    return { hasIncludeIntervals, includeIntervals };
  }

  return { hasIncludeIntervals };
};

function formatDateForSummary(value, { showTimeSelect, showTimeSelectOnly }) {
  if (!value || showTimeSelectOnly) return value;
  return formatDate(value, showTimeSelect ? 'LLLL' : 'LL');
}

export default class DateField extends PureComponent {
  render() {
    const {
      onChange,
      label,
      mode,
      name,
      value,
      showTimeSelect = false,
      showTimeSelectOnly = false,
      timeCaption,
      summary,
      includeDateIntervales = [],
      openToDate,
      timeIntervals = 10,
      storeIsoDate = false,
      lang,
      fromToday = false,
      dateFormat,
      required,
    } = this.props;

    const finalDateFormat = dateFormat ? dateFormat : showTimeSelect ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd';

    if (mode === 'summary') {
      if (summary?.hidden) {
        return null;
      }

      const formattedDate = formatDateForSummary(value, { dateFormat, showTimeSelect, showTimeSelectOnly });
      return <StringField label={summary?.label || label} value={formattedDate} mode="summary" />;
    }

    const selected = parseDateString(value, { showTimeSelect, showTimeSelectOnly, storeIsoDate });

    const { hasIncludeIntervals, includeIntervals } = computeIntervals(includeDateIntervales, showTimeSelect);
    const defaultOpenToDate = hasIncludeIntervals && orderBy(includeIntervals, 'start', 'asc')?.[0]?.start;

    function formatSaveDate(date) {
      if (!date) return null;
      const mDate = moment(date);
      if (!showTimeSelect) {
        return mDate.format('YYYY-MM-DD');
      }
      if (storeIsoDate) {
        return mDate.toISOString();
      }
      if (showTimeSelectOnly) {
        return mDate.format('HH:mm');
      }
      // Keep date using the user's timezone formatting
      return mDate.format('YYYY-MM-DD HH:mm');
    }

    return (
      <FieldContainer {...this.props}>
        <DatePicker
          className="af-string-field"
          // Will use default locale if not defined :)
          locale={lang && lang in locales ? lang : undefined}
          selected={selected}
          name={name}
          isClearable={false}
          onChange={(date) => onChange(name, formatSaveDate(date))}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeFormat="HH:mm"
          timeIntervals={timeIntervals}
          dateFormat={finalDateFormat}
          timeCaption={timeCaption || 'time'}
          includeDateIntervals={includeIntervals}
          openToDate={(openToDate && new Date(openToDate)) || defaultOpenToDate}
          filterDate={fromToday ? isAfterToday : undefined}
          required={required}
        />
      </FieldContainer>
    );
  }
}
