/* eslint-disable react/prop-types */
import cx from 'classnames';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isNumber from 'lodash/isNumber';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import min from 'lodash/min';
import toNumber from 'lodash/toNumber';
import moment from 'moment';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ActivityRegistration } from './components';
import Countdown from './components/Countdown';
import Steps from './components/Steps';
import PostSummaryBlock from './components/summary/PostSummaryBlock';
import PreSummaryBlock from './components/summary/PreSummaryBlock';
import { UpdateMode } from './components/summary/summary.types';
import { SubmittableContext } from './context/Submittable';
import { DesignContext } from './core/Design';
import AutomaticFields from './fields/AutomaticFields';
import FormFields from './fields/FormFields';
import { getCurrentTabs, TabsContext } from './fields/Tabs/Tabs';
import { formFields } from './formFields';
import registrationV2Service from './services/registrationV2.service';
import ticketingService from './services/ticketing.service';
import Styles from './Styles';
import Ticketing from './Ticketing';
import { getDefaultData, hasProducts, initializeData, isServiceV2, isUpdate } from './utils';
import { scrollToTop } from './utils/domUtils';
import { populateQuotaFields } from './utils/fieldUtils';
import { FormMode, isUpdateWithSummary } from './utils/formUtils';
import Images from './utils/Images';
import { setIn } from './utils/objectUtils';
import { getPrices, performPayment } from './utils/payment';
import { cleanWorkshopRegistrations, extractGuestFields, sanitizeData } from './utils/sanitizeData';
import { extractUTM, getParameterByName } from './utils/urlUtils';

const hasDebug = getParameterByName('debug') === 'true' || process.env.NODE_ENV == 'development';
const hasDebugData = getParameterByName('debugData') === 'true';

const MySwal = withReactContent(Swal);

export function sweetAlert(options = {}) {
  const { title, text, icon = 'info', ...rest } = options;
  return MySwal.fire({
    title,
    text,
    icon,
    ...rest,
  });
}

// Inject formFields on FormFields
FormFields.formFields = formFields;

// Vérifier le formulaire sur IE => IE11

let fields, automaticFields;
let options = {};
let design = {
  primaryColor: '#FD5E0F',
};
let ticketing = {};
let activeTab = undefined;
let lang;

if (window.__DATA__) {
  const data = window.__DATA__;
  if (data.fields) {
    fields = data.fields;
  }
  if (data.ticketing) {
    ticketing = data.ticketing;
  }
  if (data.automaticFields) {
    automaticFields = data.automaticFields;
  }
  if (data.design) {
    design = data.design;
  }
  if (data.options) {
    options = data.options;
  }
  if (data.activeTab) {
    activeTab = data.activeTab;
  }
  if (data.lang) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    lang = data.lang;
  }
}

/**
 * Used to inject total/deposit prices in the form, as displayed by recap tables
 */
class PriceInjector extends Component {
  render() {
    const { fields, data, stripeToken } = this.props;

    const userCount = (data.users && data.users.length) || 1;
    const { total, deposit } = getPrices(fields, data, userCount);

    return (
      <div style={{ display: 'none' }}>
        <input name="payment[totalValue]" value={total} />
        <input name="payment[depositValue]" value={deposit} />
        {/* TODO : clean up this part... */}
        {stripeToken && data.paymentType === 'cb' && <input name="payment[stripeToken]" value={stripeToken} />}
      </div>
    );
  }
}

function getDisableMailing() {
  const { disableMailing } = window.__DATA__;
  return disableMailing;
}

function hasFormTicketing() {
  const { ticketing } = window.__DATA__;
  return ticketing?.enabled;
}

export function isSummaryModeForced() {
  const { options } = window.__DATA__;
  return options?.forceSummaryDefaultMode;
}

function getFormFieldsWithTicketingFields(fields) {
  const { ticketing } = window.__DATA__;
  const hasTicketing = hasFormTicketing();
  return hasTicketing ? [...fields, ...(ticketing?.fields || [])] : fields;
}

function getDefaultFormMode() {
  if (isSummaryModeForced()) return FormMode.SUMMARY;
  if (isUpdateWithSummary()) return FormMode.UPDATE_SUMMARY;
  const hasTicketing = hasFormTicketing();
  if (hasTicketing) return FormMode.TICKETING;
  return FormMode.FORM;
}

function getCurrentStepAndMode(step, currentTabs, defaultMode) {
  const { steps } = options || {};
  const isFormFinished = steps && step && step >= currentTabs?.length;
  if (isFormFinished) {
    const isSummary = steps?.find((s, index) => s.value === 'summary' && index === step);
    if (isSummary) {
      return { currentStep: step, mode: FormMode.SUMMARY };
    }
    return { currentStep: undefined, mode: FormMode.SUMMARY };
  }
  return { currentStep: step, mode: defaultMode };
}

/**
 * Return FormMode.SUMMARY for SUMMARY and UPDATE_SUMMARY
 * @param {*} mode
 * @returns
 */
function normalizeMode(mode) {
  if (mode === FormMode.SUMMARY || mode === FormMode.UPDATE_SUMMARY) return FormMode.SUMMARY;
  return mode;
}

class Form extends Component {
  constructor(props) {
    super(props);
    const { step } = getDefaultData() || {};
    const formFields = getFormFieldsWithTicketingFields(fields);
    const data = initializeData(formFields);
    const sanitized = sanitizeData(data, formFields);
    const defaultMode = getDefaultFormMode();
    const tabsField = fields.find((field) => field.type === 'Tabs');
    const currentTabs = tabsField ? getCurrentTabs({ tabs: tabsField.tabs, autoHideEmptyTabs: true, data: sanitized }) : undefined;
    // Ensure that step doesn't overflow
    const { currentStep, mode } = getCurrentStepAndMode(step, currentTabs, defaultMode);
    this.state = {
      data,
      formData: { ...sanitized, step: currentStep },
      activeTab: currentStep, // Used by STEPS component
      fields,
      automaticFields,
      tabsField,
      hasProducts: hasProducts(fields),
      stripeToken: null,
      errors: {},
      registrationErrors: [],
      mode, // ticketing // "form" or "summary",
      hasChanged: false,
    };
  }

  formRef = React.createRef();

  isAsyncSubmitSupported = () => isServiceV2();

  componentDidMount() {
    window.addEventListener('beforeunload', this.onBeforeUnload);

    window.addEventListener(
      'message',
      (event) => {
        const data = event.data;
        if (!data.fields && !isNumber(data.activeTab)) return; // Block, invalid message
        if (data.fields) {
          fields = data.fields;
        }
        if (data.design) {
          design = data.design;
        }
        if (data.ticketing) {
          ticketing = data.ticketing;
        }
        if (isNumber(data.activeTab)) {
          activeTab = data.activeTab;
        }

        const formFields = getFormFieldsWithTicketingFields(fields);
        // Merge default state + entered data
        this.setState({
          data: { ...initializeData(formFields), ...this.state.data },
          fields,
          hasProducts: hasProducts(formFields),
          errors: {},
          activeTab,
        });
      },
      false,
    );
  }

  onBeforeUnload = (e) => {
    const { hasChanged } = this.state;

    if (hasChanged) {
      e.preventDefault();
      //necessary for Chrome
      e.returnValue = '';
    }
  };

  onChange = (fieldName, value) => {
    if (!fieldName) {
      console.error('Missing field name for', fieldName, value);
      return;
    }

    this.setState((state) => {
      let data = setIn(state.data, fieldName, value); // Keep ALL entered values in state
      //if system userCount field is present reset workshopsRegistrations
      if (fieldName === 'userCount') {
        data.workshopsRegistrations = [];
      }
      const { productBasket, accommodation } = data;
      const formFields = getFormFieldsWithTicketingFields(fields);
      const formData = sanitizeData(data, formFields);
      if (options.sanitizeWorkshops) {
        const updatedRegistrations = cleanWorkshopRegistrations(data.workshopsRegistrations, formData, formFields);
        if (hasDebug && !isEqual(updatedRegistrations, data.workshopsRegistrations)) {
          console.log('Updated workshop registrations', { previous: data.workshopsRegistrations, next: updatedRegistrations });
        }
        data.workshopsRegistrations = updatedRegistrations;
      }
      return {
        data,
        formData: {
          accommodation,
          productBasket,
          ...formData,
          workshopsRegistrations: data.workshopsRegistrations,
        },
        hasChanged: true,
      };
    });
  };

  onChangeMode = (mode) => {
    this.setState({ mode }, scrollToTop);
  };

  setSubmitDisabled = (value) => {
    const submitBtn = document.querySelectorAll('button.af-form-button[type=submit]')[0];
    if (submitBtn) {
      submitBtn.disabled = value;
    }
  };

  disableSubmitTemporarily = (duration = 2000) => {
    this.setSubmitDisabled(true);
    // Restore button in 2 seconds (just in case)
    setTimeout(() => this.setSubmitDisabled(false), duration);
  };

  buildResponseBody = (data, options = {}) => {
    const { fields, ticketingSession } = this.state;
    const { workshopsRegistrations, productBasket, step, ...formData } = data;
    const { partialUpdate } = options;

    const accommodation = data.accommodation;
    const formFields = getFormFieldsWithTicketingFields(fields);
    const sanitized = sanitizeData({ ...formData, step }, formFields);
    const utmFields = extractUTM(window.location.search);
    return {
      partialUpdate,
      registrationData: { ...sanitized, step, ...utmFields },
      disableMailing: getDisableMailing(),
      workshopsRegistrations,
      accommodation,
      productBasket,
      ticketingSession,
    };
  };

  mustShowConfirmAlert = (submitOptions) => {
    const { partialUpdate = false, isLastTab } = submitOptions;
    const { displaySummary } = options;
    const { mode } = this.state;

    // No message to show ?
    if (!options.alertConfirmMessage) return false;

    if (displaySummary) {
      // If summary, only show popup on final summary
      return mode === FormMode.SUMMARY;
    } else if (partialUpdate) {
      // Only show on last tab, not intermediate tabs
      return isLastTab;
    }

    // Final post, good to go
    return true;
  };

  promptAlert = async () => {
    const { t } = this.props;
    const { alertConfirmMessage, alertOptions = {} } = options;

    const result = await Swal.fire({
      title: alertConfirmMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: design.primaryColor,
      cancelButtonColor: '#aaa',
      confirmButtonText: t('btn.confirm'),
      cancelButtonText: t('btn.cancel'),
      ...alertOptions,
    });

    return !!result.value;
  };

  promptError = async (message) => {
    const { t } = this.props;
    const result = await Swal.fire({
      title: message,
      icon: 'error',
      confirmButtonColor: design.primaryColor,
      confirmButtonText: t('btn.back-to-form'),
    });

    return !!result.value;
  };

  showErrors = async (errors) => {
    sweetAlert({
      icon: 'error',
      width: '60%',
      html: (
        <ul>
          {errors.map((error) => (
            <li style={{ textAlign: 'left' }} key={error}>
              {error}
            </li>
          ))}
        </ul>
      ),
    });
  };

  handlePaymentRedirect = (redirectUrl) => {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    // window.location = redirectUrl; // Doesn't work in an iframe
    window.open(redirectUrl, '_blank');
    // Flush ?
    this.setState({
      fields: [
        {
          text: "Merci de payer via l'onglet qui s'est ouvert dans votre navigateur.\n\nSi l'onglet ne s'ouvre pas, cliquez ci-dessous pour accéder au paiement.",
          type: 'TextBlock',
        },
        {
          type: 'Link',
          text: 'Cliquez ici pour payer',
          url: redirectUrl,
          target: '_blank',
        },
      ],
      mode: FormMode.CONFIRMATION,
      hasChanged: false,
    });
  };

  handleSubmit = async (e, submitOptions = {}) => {
    const { partialUpdate = false, isLastTab, step } = submitOptions;
    const { displaySummary } = options;
    const done = () => this.setSubmitDisabled(false);

    let doPartialUpdate = partialUpdate;
    // Auto-disable partial update if this is the last tab
    if (partialUpdate && isLastTab && !displaySummary) {
      doPartialUpdate = false;
    }

    const { mode } = this.state;

    if (mode === FormMode.UPDATE_SUMMARY) {
      // Time to switch to form mode
      e.preventDefault();
      e.stopPropagation();
      const updateMode = window.__DATA__.options?.updateMode?.mode;
      if (updateMode === UpdateMode.SummaryWithUpdate || updateMode === UpdateMode.UpdateButtonWithoutSummary) {
        this.onChangeMode(FormMode.FORM);
      }
      return;
    }

    const showSummary = displaySummary && mode === FormMode.FORM;
    if (!doPartialUpdate && (this.mustShowConfirmAlert(submitOptions) || this.isAsyncSubmitSupported())) {
      e.preventDefault();
      e.stopPropagation();
      // For a summary, don't disable too much. For a regular post, disable 6 seconds
      this.disableSubmitTemporarily(showSummary ? 1000 : 6000);
    }

    if (this.mustShowConfirmAlert(submitOptions)) {
      const hasConfirmed = await this.promptAlert();
      if (!hasConfirmed) {
        return done();
      }
    }

    if (showSummary && !doPartialUpdate) {
      this.onChangeMode(FormMode.SUMMARY);
      return;
    }

    if (this.isAsyncSubmitSupported()) {
      const { stepToBackToAfterValidation } = options || {};
      // compatible with v2 registration service
      const { data, fields: formFields } = this.state;
      // step is undefined when we confirm form, go back to the defined step
      const finalStep = step === undefined && (stepToBackToAfterValidation || stepToBackToAfterValidation === 0) ? stepToBackToAfterValidation : step;
      const body = this.buildResponseBody({ ...data, step: finalStep }, { partialUpdate: doPartialUpdate });
      const res = await registrationV2Service.upsertResponse(body);
      if (res?.success) {
        const { paymentMethod } = body.registrationData;
        const { fields, payment, userInfo } = res;

        if (payment?.data && paymentMethod === 'CB' && payment?.totalWithTax) {
          const { url, elements, redirectUrl } = payment?.data;
          if (redirectUrl) {
            this.handlePaymentRedirect(redirectUrl);
          } else {
            const paymentBody = mapValues(keyBy(elements, 'name'), 'value');
            this.setState({ hasChanged: false }, () => {
              performPayment(url, paymentBody);
            });
          }
        } else {
          if (doPartialUpdate) {
            // Update data with guest information
            if (userInfo) {
              const guestFields = extractGuestFields(formFields, userInfo);
              const guestPatch = pick(
                userInfo,
                guestFields.map((g) => g.name),
              );
              if (!isEmpty(guestPatch)) {
                this.setState(({ data, formData }) => {
                  return { data: { ...data, ...guestPatch }, formData: { ...formData, ...guestPatch } };
                });
              }
            }

            if (isLastTab && showSummary) {
              this.onChangeMode(FormMode.SUMMARY);
            }
            return;
          }
          this.setState({ data: initializeData(fields), fields: res.fields, mode: FormMode.CONFIRMATION, hasChanged: false }, scrollToTop);
          if (window.parent) {
            window.parent.postMessage({ type: 'registration:step', payload: { step: FormMode.CONFIRMATION } }, '*');
          }
        }
      } else if (res?.errors) {
        console.log('found errors', res?.errors);
        await this.processErrors(data, res);
        done();
        return { error: true, errors: res?.errors };
      }

      return done();
    }

    // fallback: native browser submit (v1 registration-service)
    this.formRef.current.submit();
  };

  processErrors = async (data, res) => {
    const { t } = this.props;
    if (res?.errors[0].code === 'E_FIELD_QUOTA_FULL') {
      const { fields } = this.state;
      const { field, countUserFields } = res?.errors[0];
      const userValue = get(data, field?.name);
      const option = field?.options?.find((opt) => opt.value === userValue);
      console.log({ userValue, option });
      this.showErrors([t('errors.E_FIELD_QUOTA_FULL', { label: field?.label || field?.name, value: option?.label })]);

      // Update quotas
      this.setState({
        fields: populateQuotaFields(fields, countUserFields),
      });
      return;
    }
    if (res?.errors[0].code === 'E_USER_EXISTS') {
      const { duplicateMessage } = res?.errors[0];
      if (duplicateMessage && Array.isArray(duplicateMessage)) {
        this.showErrors(duplicateMessage);
      } else {
        await this.promptError(res?.errors[0]?.duplicateMessage || t('errors.duplicate-user'));
      }
    }
    if (res?.errors[0].code === 'E_ACCOMMODATION_FULL') {
      this.showErrors([t('errors.E_ACCOMMODATION_FULL')]);
    }
    if (res?.errors[0].code === 'E_INVALID_RESERVATION_DATES') {
      this.showErrors([t('errors.E_INVALID_RESERVATION_DATES')]);
    }
    if (res?.errors[0].code === 'E_SESSION_FULL' || res?.errors[0].reason === 'sessionFull') {
      const sessionQuota = res?.errors[0].sessionQuota;
      await this.promptError(`${t('sessionFull', { sessionQuota })}`);
    } else {
      console.log('set state to', data);
      this.setState({
        registrationErrors: res.errors,
        data: { ...data },
        fields: res?.fields ?? this.state.fields,
      });
    }
  };

  handleStartTicketingSession = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { ticketing } = window.__DATA__;
    const { userCountField = 'userCount' } = ticketing;
    const { formData } = this.state;
    const { workshopsRegistrations = [] } = formData;
    const userCount = toNumber(formData[userCountField] || 1);
    const res = await ticketingService.startTicketingSession({
      userCount,
      workshopsRegistrations,
    });
    const { success, ticketingSession } = res;
    if (success) {
      const { bookingDuration, expirationDate } = ticketingSession;
      const maxExpirationDate = moment().add(bookingDuration, 'milliseconds').toISOString();
      this.setState(
        { ticketingSession: { ...ticketingSession, expirationDate: min([expirationDate, maxExpirationDate]) }, mode: FormMode.FORM },
        scrollToTop,
      );
    } else {
      if (res?.errors[0].code === 'E_SESSION_FULL') {
        const fullSessionError = get(ticketing, ['strings', 'full-session-error']);
        await this.promptError(fullSessionError || 'La session que vous avez choisi est malheureusement complète.');
      } else {
        const TicketingError = get(ticketing, ['strings', 'ticketing-error']);
        await this.promptError(TicketingError || 'Une erreur est survenue lors de la réservation !');
      }
    }
  };

  handleCancelTicketingSession = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { ticketingSession } = this.state;
    this.onChangeMode(FormMode.TICKETING);
    this.setState({ ticketingSession: null });
    if (!ticketingSession) return;
    await ticketingService.cancelTicketingSession(ticketingSession.sessionId);
    window.location.reload(false);
  };

  hasStepTabs = () => {
    const { steps } = options || {};
    if (!steps?.length) return undefined;

    // Embed tabs in steps ?
    return !!steps.find((step) => step.value === 'form' && step.embedTabs);
  };

  getSteps = () => {
    const { steps } = options || {};
    const { formData, fields, tabsField: stateTabsField } = this.state;
    if (!steps?.length) return undefined;

    // Embed tabs in steps ?
    if (this.hasStepTabs()) {
      return flatten(
        steps.map((step) => {
          if (step.value === 'form' && step.embedTabs) {
            // Find tabs and embed. Fallback to tabsField to handle confirmation (doesn't have the tabs)
            const tabsField = fields.find((field) => field.type === 'Tabs') || stateTabsField;
            const currentTabs = getCurrentTabs({ tabs: tabsField.tabs, autoHideEmptyTabs: true, data: formData });
            return currentTabs.map((tab, index) => ({
              value: 'form',
              tabId: tab._id,
              tabIndex: index,
              label: tab.label,
            }));
          }
          return [step];
        }),
      );
    }
    return steps;
  };

  handleTabChange = (activeTab) => this.setState({ activeTab });

  handleGoToStep = async (e, tabIndex) => {
    const { tabsField, formData, mode: currentMode, activeTab } = this.state;
    const { persistOnTabChange, showNextTabOnUpdate } = tabsField;
    const isLast = tabsField?.tabs?.length - 1 === tabIndex;
    const defaultMode = getDefaultFormMode();
    const currentTabs = getCurrentTabs({ tabs: tabsField.tabs, autoHideEmptyTabs: true, data: formData });
    const { mode } = getCurrentStepAndMode(tabIndex, currentTabs, defaultMode);
    if (mode === FormMode.FORM) this.setState({ activeTab: tabIndex });

    if (currentMode !== mode) {
      this.onChangeMode(mode);
    }

    if (!persistOnTabChange && (isLast || (isUpdate() && !showNextTabOnUpdate))) {
      // Force disable to debounce clicks
      return; // Let normal submit...
    }

    e.preventDefault();
    e.stopPropagation();

    if (persistOnTabChange) {
      const prevTabIndex = this.state.data.step;
      try {
        this.onChange?.('step', tabIndex);
        const res = await this.handleSubmit(e, { step: tabIndex, partialUpdate: true, isLastTab: isLast });
        if (!res?.error) {
          return; // Finished !
        }
      } catch (e) {
        console.error('handleGoToStep failed', e);
      }
      // Undo step change
      // Revert
      this.onChange?.('step', prevTabIndex);
      this.onChangeMode(currentMode);
      const { mode } = getCurrentStepAndMode(prevTabIndex, currentTabs, defaultMode);
      if (mode === FormMode.FORM) this.setState({ activeTab });
    }
  };

  getTabsContext() {
    if (this.hasStepTabs()) {
      // Context to manipulate tabs
      return { hideTabHeader: true, onTabChange: this.handleTabChange };
    }
    return {}; // No context
  }

  updateFields = (fields) => this.setState({ fields });

  renderBackgroundImage = () => {
    const { backgroundImage } = design;
    if (!backgroundImage?.uri) return null;
    return <div className="af-container__background" />;
  };

  renderHeader = () => {
    const { header } = design;
    if (!header) return null;
    return (
      <div className="af-form-header">
        <img src={Images.maxWidth(header.uri, 1200)} alt="" />
      </div>
    );
  };

  renderForm = () => {
    const { tabsField, formData, stripeToken, fields, ticketingSession, automaticFields, errors, registrationErrors, hasProducts, activeTab, mode } =
      this.state;
    const submitUrl = window.__DATA__ && window.__DATA__.submitUrl;
    const steps = this.getSteps();
    const { width = 'normal', border = true, variant = 'paper' } = design;

    if (hasDebug) console.log('debug', this.state);
    if (hasDebugData) console.log(JSON.stringify(this.state.formData, null, 2));

    if (mode === FormMode.TICKETING) {
      return (
        <form
          className={cx('af-form-container', `af-form-container--${mode}`)}
          method="post"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {this.renderHeader()}
          <div className="af-form-fields-container">
            <Ticketing data={formData} ticketing={ticketing} onChange={this.onChange} onValidate={this.handleStartTicketingSession} />
          </div>
        </form>
      );
    }
    return (
      <form
        ref={this.formRef}
        className={cx('af-form-container', `af-form-container--${mode}`, `af-form-container--${variant}`, `af-form-container--width-${width}`, {
          'af-form-container--border': border,
        })}
        method="post"
        action={submitUrl}
        onSubmit={this.handleSubmit}
      >
        {this.renderHeader()}
        <div className="af-form-fields-container">
          {ticketingSession && mode !== FormMode.CONFIRMATION && (
            <Countdown data={formData} session={ticketingSession} onCancelSession={this.handleCancelTicketingSession} />
          )}
          <Steps steps={steps} activeStep={mode} activeTab={activeTab} tabsField={tabsField} onGoToStep={this.handleGoToStep} data={formData} />
          <AutomaticFields data={formData} automaticFields={automaticFields} />
          {mode === FormMode.UPDATE_SUMMARY && <PreSummaryBlock data={formData} options={window.__DATA__.options?.updateMode} />}
          <TabsContext.Provider value={this.getTabsContext()}>
            {(mode !== FormMode.UPDATE_SUMMARY || window.__DATA__.options?.updateMode?.mode !== UpdateMode.UpdateButtonWithoutSummary) && (
              <FormFields
                fields={fields}
                onChange={this.onChange}
                onChangeMode={this.onChangeMode}
                data={formData}
                errors={errors}
                activeTab={activeTab}
                registrationErrors={registrationErrors}
                mode={normalizeMode(mode)}
                formMode={mode}
              />
            )}
          </TabsContext.Provider>
          {mode === FormMode.UPDATE_SUMMARY && <PostSummaryBlock data={formData} options={window.__DATA__.options?.updateMode} />}
        </div>
        {hasProducts && <PriceInjector fields={fields} data={formData} stripeToken={stripeToken} />}
      </form>
    );
  };

  render() {
    const { fields } = this.state;
    ActivityRegistration.fields = fields; // TODO : use store
    ActivityRegistration.updateFields = this.updateFields; // TODO : use store

    return (
      <div>
        <Styles {...design} />
        <SubmittableContext.Provider value={{ submit: this.handleSubmit }}>
          <DesignContext.Provider value={design}>
            {this.renderBackgroundImage()}
            {this.renderForm()}
          </DesignContext.Provider>
        </SubmittableContext.Provider>
      </div>
    );
  }
}

export default withTranslation()(Form);
